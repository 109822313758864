import Http from '../api/http/';

export default class Role extends Http {
  constructor() {
    super();
  }
  // 审批
  schoolRegistration_approval() {
    return super.post('  schoolRegistration/approval', ...arguments);
  }
  // 详情
  schoolRegistration_detail() {
    return super.post('schoolRegistration/detail', ...arguments);
  }
  // 导出
  schoolRegistration_export() {
    return super.post('schoolRegistration/export', ...arguments);
  }
  // 列表查询
  schoolRegistration_getList() {
    return super.post('schoolRegistration/getList', ...arguments);
  }
}
