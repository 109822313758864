import Http from '../api/http/';

export default class SystemSetting extends Http {
  constructor() {
    super();
  }
  // 添加品牌
  systemSetting_brand_add() {
    return super.post('systemSetting/brand/add', ...arguments);
  }
  //删除品牌
  systemSetting_brand_delete() {
    return super.post('systemSetting/brand/delete', ...arguments);
  }
  //修改品牌
  systemSetting_brand_update() {
    return super.post('systemSetting/brand/update', ...arguments);
  }
  //常用语列表
  systemSetting_phrases_list() {
    return super.post('systemSetting/phrases/list', ...arguments);
  }
  //广告开关
  systemSetting_onOff() {
    return super.post('systemSetting/onOff', ...arguments);
  }
  //广告开关状态
  systemSetting_onOffStatus() {
    return super.post('systemSetting/onOffStatus', ...arguments);
  }
  /**
   * 品牌列表
   * @param {Array} cooperativeBrandDto -  cooperativeBrandDto
   */
  systemSetting_brand_list() {
    return super.post('systemSetting/brand/list', ...arguments);
  }

  /**
   * 变更合作品牌在线状态
   * @param {Array} cooperativeBrandDto -  cooperativeBrandDto
   */
  systemSetting_brand_modifyOnlineStatus() {
    return super.post('systemSetting/brand/modifyOnlineStatus', ...arguments);
  }
  //新增APP开屏页广告
  systemSetting_appStartAdAdd() {
    return super.post('systemSetting/appStartAdAdd', ...arguments, {});
  }
  //app开屏页广告删除
  systemSetting_appStartAdDelete() {
    return super.post('systemSetting/appStartAdDelete', ...arguments);
  }
  //APP开屏页详情
  systemSetting_appStartAdDetail() {
    return super.post('systemSetting/appStartAdDetail', ...arguments);
  }
  //开屏页列表
  systemSetting_appStartAdList() {
    return super.post('systemSetting/appStartAdList', ...arguments);
  }
  //上下线
  systemSetting_appStartAdOnOffLine() {
    return super.post('systemSetting/appStartAdOnOffLine', ...arguments);
  }
  //修改
  systemSetting_appStartAdUpdate() {
    return super.post('systemSetting/appStartAdUpdate', ...arguments, {});
  }
  //标签列表
  systemSetting_userLabelList() {
    return super.post('systemSetting/userLabelList', ...arguments);
  }
  //新增标签
  systemSetting_userLabelConfigAdd() {
    return super.post('systemSetting/userLabelConfigAdd', ...arguments);
  }
  //删除标签
  systemSetting_userLabelConfigRemove() {
    return super.post('systemSetting/userLabelConfigRemove', ...arguments);
  }
  // 删除常用语
  systemSetting_phrases_delete() {
    return super.post('systemSetting/phrases/delete', ...arguments);
  }
  //添加常用语
  systemSetting_phrases_add() {
    return super.post('systemSetting/phrases/add', ...arguments);
  }
  //修改常用语
  systemSetting_phrases_update() {
    return super.post('systemSetting/phrases/update', ...arguments);
  }
  /**
   * 批量导入用户标签
   * @param {file} awardFile -  上传文件
   */
  systemSetting_batchImportUserLabel() {
    return super.post('systemSetting/batchImportUserLabel', ...arguments);
  }
  /**
   * 获取用户标签下拉列表
   */
  systemSetting_getAllUserLabel() {
    return super.post('systemSetting/getAllUserLabel', ...arguments);
  }
  /**
   * 获取标签的标记数据
   */
  systemSetting_userListByLabel() {
    return super.post('systemSetting/userListByLabel', ...arguments);
  }
  /**
   * 导出标签的标记数据
   */
  systemSetting_exportUserListByLabel() {
    return super.post('systemSetting/exportUserListByLabel', ...arguments);
  }
}
