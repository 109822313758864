import Http from '../api/http/';
export default class BusinessStore extends Http {
  constructor() {
    super();
  }
  //获取门店列表
  businessStore_getStoreList() {
    return super.post('businessStore/getStoreList', ...arguments);
  }
  //通过商圈获取到所有的门店
  businessStore_getStoreListByBusinessCity() {
    return super.post('businessStore/getStoreListByBusinessCity', ...arguments);
  }
  //更改商圈下门店
  businessStore_updateBusinessStore() {
    return super.post('businessStore/updateBusinessStore', ...arguments);
  }
}
