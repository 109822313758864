import Http from '../api/http/';
export default class Invoice extends Http {
  constructor() {
    super();
  }
  // 发票列表
  invoiceV2_getList() {
    return super.post('invoiceV2/getList', ...arguments);
  }
  // 发票下拉列表
  invoiceV2_getListNotPage() {
    return super.post('invoiceV2/getListNotPage', ...arguments);
  }
  // 删除发票
  invoiceV2_deleteInvoiceList() {
    return super.post('invoiceV2/deleteInvoiceList', ...arguments);
  }
  // 导出发票
  invoiceV2_export() {
    return super.post('invoiceV2/export', ...arguments);
  }
  // 导入发票
  invoiceV2_import() {
    return super.post('invoiceV2/import', ...arguments);
  }
  // 发票详情
  invoiceV2_detail() {
    return super.post('invoiceV2/detail', ...arguments);
  }
  // 新增发票
  invoiceV2_addInvoiceList() {
    return super.post('invoiceV2/addInvoiceList', ...arguments);
  }
  // 修改发票
  invoiceV2_updateInvoiceList() {
    return super.post('invoiceV2/updateInvoiceList', ...arguments);
  }
  // 发票 关联主体 - 门店
  invoiceV2_getInvoiceStoreInfos() {
    return super.post('invoiceV2/getInvoiceStoreInfos', ...arguments);
  }
  // 发票 关联主体 - 区域
  invoiceV2_getInvoiceAreaInfos() {
    return super.post('invoiceV2/getInvoiceAreaInfos', ...arguments);
  }
  // 发票 关联主体 - 品牌
  invoiceV2_getInvoiceBrandInfos() {
    return super.post('invoiceV2/getInvoiceBrandInfos', ...arguments);
  }
  // 发票 导入修改发票模板
  invoiceV2_exportByEdit() {
    return super.post('invoiceV2/exportByEdit', ...arguments);
  }
  // 发票 导入修改发票
  invoiceV2_importEdit() {
    return super.post('invoiceV2/importEdit', ...arguments);
  }
}
