import Http from '../api/http/';

export default class CompanyWallet extends Http {
  constructor() {
    super();
  }

  /**
   * 导出充值明细
   * @param {string} id -  undefined
   * @param {array} companyIds -  undefined
   * @param {string} companyId -  undefined
   * @param {integer} receiveVendorId -  收款渠道，1支付宝2微信'
   * @param {integer} rechargeStatus -  充值状态'0待充值1充值成功2二维码失效未成功支付
   * @param {string} payTimeBegin -  开始时间,yyyy-MM-dd
   * @param {string} payTimeEnd -  结束时间,yyyy-MM-dd
   * @param {string} principalName -  负责人
   * @param {string} departmentName -  部门
   * @param {integer} page -  页数
   * @param {integer} rows -  行数
   */
  companyWallet_exportWalletRecharge() {
    return super.post('companyWallet/exportWalletRecharge', ...arguments, {});
  }

  /**
   * 企业充值表
   * @param {string} id -  undefined
   * @param {array} companyIds -  undefined
   * @param {string} companyId -  undefined
   * @param {integer} receiveVendorId -  收款渠道，1支付宝2微信'
   * @param {integer} rechargeStatus -  充值状态'0待充值1充值成功2二维码失效未成功支付
   * @param {string} payTimeBegin -  开始时间,yyyy-MM-dd
   * @param {string} payTimeEnd -  结束时间,yyyy-MM-dd
   * @param {string} principalName -  负责人
   * @param {string} departmentName -  部门
   * @param {integer} page -  页数
   * @param {integer} rows -  行数
   */
  companyWallet_getWalletRechargeList() {
    return super.post('companyWallet/getWalletRechargeList', ...arguments, {});
  }
  // 收款表
  companyWallet_getReceiveMoneyList() {
    return super.post('companyWallet/getReceiveMoneyList', ...arguments, {});
  }
  // 钱包充值
  companyWallet_getWalletManuallyAddRechargeList() {
    return super.post('companyWallet/getWalletManuallyAddRechargeList', ...arguments);
  }
  // 钱包退款
  companyWallet_getWalletManuallyRefundRechargeList() {
    return super.post('companyWallet/getWalletManuallyRefundRechargeList', ...arguments);
  }
  // 人工录入充值
  companyWallet_manuallyAddRecharge() {
    return super.post('companyWallet/manuallyAddRecharge', ...arguments);
  }
  // 人工录入退款
  companyWallet_manuallyRefundRecharge() {
    return super.post('companyWallet/manuallyRefundRecharge', ...arguments);
  }
  //确认收款
  companyWallet_confirmMainCountRechargeToCompany() {
    return super.post('companyWallet/confirmMainCountRechargeToCompany', ...arguments);
  }
  companyWallet_cancelConfirmRechargeToCompany() {
    return super.post('companyWallet/cancelConfirmRechargeToCompany', ...arguments);
  }
  //  根据企业id查询 获取未核销的账单列表
  companyWallet_getHaveResidueRelateEstimateList() {
    return super.post('companyWallet/getHaveResidueRelateEstimateList', ...arguments);
  }
  // 根据充值id查询 获取已经核销的账单列表
  companyWallet_getRechargeRelationEstimateBillList() {
    return super.post('companyWallet/getRechargeRelationEstimateBillList', ...arguments);
  }
  // 核销收款
  companyWallet_relationRechargeEstimate() {
    return super.post('companyWallet/relationRechargeEstimate', ...arguments);
  }
  // 取消核销
  companyWallet_cancelRelationEstimateRecharge() {
    return super.post('companyWallet/cancelRelationEstimateRecharge', ...arguments);
  }
  // 获取剩余核销收款金额
  companyWallet_getRechargeResidueRelateMoney() {
    return super.post('companyWallet/getRechargeResidueRelateMoney', ...arguments);
  }
  // 导出收款列表
  companyWallet_exportWalletReceive() {
    return super.post('companyWallet/exportWalletReceive', ...arguments);
  }
  /**
   * 获取调账列表
   * @param {string} companyId -  企业id
   * @param {integer} rechargeId -  收款ID
   * @param {integer} page -  页数
   * @param {integer} rows -  行数
   */
  companyWallet_getAdjustRechargeCompanyList() {
    return super.post('companyWallet/getAdjustRechargeCompanyList', ...arguments);
  }

  /**
   * 调账(修改企业)
   * @param {string} companyId -  企业id
   * @param {integer} rechargeId -  流水id
   * @param {integer} belongBusinessType -  归属 嗨探.1 巡店.2
   */
  companyWallet_adjustRechargeCompany() {
    return super.post('companyWallet/adjustRechargeCompany', ...arguments);
  }
  /**
   * 修改收入归属;
   * @param {string} companyId -  企业id
   * @param {integer} rechargeId -  收款id
   * @param {integer} belongBusinessType -  归属 嗨探.1 巡店.2
   */
  companyWallet_changeRechargeBelongBusinessType() {
    return super.post('companyWallet/changeRechargeBelongBusinessType', ...arguments);
  }
  /**
   * 调账校验
   * @param {string} companyId -  企业id
   * @param {integer} rechargeId -  收款id
   */
  companyWallet_adjustRechargeCompanyCheck() {
    return super.post('companyWallet/adjustRechargeCompanyCheck', ...arguments);
  }
  /**
   * 核销收款校验
   * @param {integer} rechargeId -  收款id
   */
  companyWallet_relationRechargeEstimateCheck() {
    return super.post('companyWallet/relationRechargeEstimateCheck', ...arguments);
  }
  /**
   * 重新推送
   * @param {integer} rechargeAdjustId -  调账id
   */
  companyWallet_pushRechargeAdjustToCrm() {
    return super.post('companyWallet/pushRechargeAdjustToCrm', ...arguments);
  }
}
