import Http from '../api/http/';

export default class Statistics extends Http {
  constructor() {
    super();
  }

  /**
   * 付款明细列表
   * @param year
   */
  statistics_monthlyPayInfo() {
    return super.post('statistics/monthlyPayInfo', ...arguments);
  }

  /**
   * 付款明细的单据列表
   * @param yearMonth
   */
  statistics_monthlyPayList() {
    return super.post('statistics/monthlyPayList', ...arguments);
  }

  /**
   * 付款信息访客列表
   * @param yearMonth
   */
  statistics_monthlyPayVisitorList() {
    return super.post('statistics/monthlyPayVisitorList', ...arguments);
  }
  /**
   * 业务计件表
   * @param year
   * @param month
   * @param operatorId
   * @param brandId
   * @param enterpriseId
   */
  statistics_businessPieceWorkStatistics() {
    return super.post('statistics/businessPieceWorkStatistics', ...arguments);
  }

  /**
   * 业务计件表
   * @param year
   * @param month
   * @param operatorId
   * @param enterpriseId
   */
  statistics_financialRevenuesStatistics() {
    return super.post('statistics/financialRevenuesStatistics', ...arguments);
  }
  //中奖情况报表
  statistics_businessDrawStatistic() {
    return super.post('statistics/businessDrawStatistic', ...arguments);
  }
  //商圈用户报名情况表
  statistics_businessUserDetailStatistic() {
    return super.post('statistics/businessUserDetailStatistic', ...arguments);
  }
  //用户画像
  statistics_userPortrait() {
    return super.post('statistics/userPortrait', ...arguments);
  }

  /***
   * 任务分布图
   * yearMonth （202005）
   */
  statistics_taskUserDistribution() {
    return super.post('statistics/taskUserDistribution', ...arguments);
  }
  /***
   * 任务分布列表
   * cityId
   * yearMonth （202005）
   */
  statistics_taskUserDistributionList() {
    return super.post('statistics/taskUserDistributionList', ...arguments);
  }
  /***
   * 任务分布导出
   * cityId
   * yearMonth （202005）
   */
  statistics_outportTaskUserDistribution() {
    return super.get('statistics/outportTaskUserDistribution', ...arguments);
  }
  /***
   * 商圈门店详情报表
   * yearMonth
   * businessId
   */
  statistics_getBusinessStoreStatistic() {
    return super.post('statistics/getBusinessStoreStatistic', ...arguments);
  }
  /***
   * 虚拟电话计费导出
   * companyId
   * yearMonth （202005）
   */
  statistics_outportPrivateCallsDetail() {
    return super.get('statistics/outportPrivateCallsDetail', ...arguments);
  }

  /**
   * 虚拟电话计费详情
   * @param {string} yearMonth -  年月
   * @param {string} companyId -  企业id
   * @param {string} talkStatus -  通话状态 dealing（已接听）、notDeal（振铃未接听）、queueLeak（排队放弃）、voicemail（已留言）、 leak（IVR放弃） 、blackList（黑名单）
   * @param {string} seatPlatName -  坐席绑定平台姓名
   * @param {array} callChatIds -  undefined
   * @param {integer} talkWay -  接打方式1电脑2话机3手机
   * @param {integer} page -  页数
   * @param {integer} rows -  行数
   */
  statistics_privateCallsDetail() {
    return super.post('statistics/privateCallsDetail', ...arguments);
  }

  /***
   * 虚拟电话计费列表
   * year
   * month
   * companyId
   */
  statistics_privateCallsList() {
    return super.post('statistics/privateCallsList', ...arguments);
  }

  /**
   * 指定报表列表
   * @param {Array} appointTaskDto -  appointTaskDto
   */
  statistics_appointTaskList() {
    return super.post('statistics/appointTaskList', ...arguments, {});
  }
  /**
   * 指定报表列表导出
   * @param {Array} appointTaskDto -  appointTaskDto
   */
  statistics_appointTaskListOutPort() {
    return super.post('statistics/appointTaskListOutPort', ...arguments, {});
  }

  /**
   * B端计件表
   * @param {Array} bEnterprisePieceWorkDTO -  bEnterprisePieceWorkDTO
   */

  statistics_bEnterprisePieceworkStatistics() {
    return super.post('statistics/bEnterprisePieceworkStatistics', ...arguments);
  }

  /**
   * B端计件表导出
   * @param {Array} bEnterprisePieceWorkDTO -  bEnterprisePieceWorkDTO
   */
  statistics_bEnterprisePieceworkExport() {
    return super.get('statistics/bEnterprisePieceworkExport', ...arguments);
  }

  /**
   * @description 报告一次性通过率
   */
  statistics_reportOnePassRateStatistics() {
    return super.post('statistics/reportOnePassRateStatistics', ...arguments);
  }
  /**
   * @description 报告一次性通过率导出
   */
  statistics_reportOnePassRateStatisticsExport() {
    return super.get('statistics/reportOnePassRateStatisticsExport', ...arguments);
  }
  /**
   * @description 报告一次性通过率详情
   */
  statistics_reportOnePassRateStatisticsDetail() {
    return super.post('statistics/reportOnePassRateStatisticsDetail', ...arguments);
  }
  /**
   * @description 报告一次性通过率详情导出
   */
  statistics_reportOnePassRateStatisticsDetailExport() {
    return super.get('statistics/reportOnePassRateStatisticsDetailExport', ...arguments);
  }

  // 城市任务数
  statistics_cityBusinessTask() {
    return super.post('statistics/cityBusinessTask', ...arguments);
  }
  statistics_exportCityBusinessTask() {
    return super.post('statistics/exportCityBusinessTask', ...arguments);
  }
}
