import Http from '../api/http/';

export default class DataExamine extends Http {
  constructor() {
    super();
  }
  /***********数据分析考核点管理***************/
  /**
   * 考核点管理添加
   * @param {Array} dataExamineDto -  dataExamineDto
   */
  dataExamine_add() {
    return super.post('dataExamine/add', ...arguments);
  }

  /**
   * 删除
   * @param {string} id -  id
   */
  dataExamine_delete() {
    return super.post('dataExamine/delete', ...arguments);
  }
  /**
   * 查询所有（下拉框）
   */
  dataExamine_getList() {
    return super.post('dataExamine/getList', ...arguments);
  }

  /**
   * 考核点管理分页查询
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   * @param {string} name -  考核点名称
   */
  dataExamine_getListByPage() {
    return super.post('dataExamine/getListByPage', ...arguments);
  }

  /**
   * 考核点管理修改
   * @param {Array} dataExamineDto -  dataExamineDto
   */
  dataExamine_update() {
    return super.post('dataExamine/update', ...arguments);
  }

  /**
   * 考核点导入
   * @param file
   */
  dataExamine_importExcel() {
    return super.post('dataExamine/importExcel', ...arguments);
  }
}
