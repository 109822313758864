import Http from '../api/http/';
export default class HelpCenter extends Http {
  constructor() {
    super();
  }
  /***********帮助中心***************/
  /**
   * 帮助列表
   * @param {string} title -  标题
   */

  helpCenter_helpList() {
    return super.post('helpCenter/helpList', ...arguments);
  }


  /**
   * 更新记录列表
   * @param {integer} page -  页数
   * @param {integer} rows -  页面容量
   */

  helpCenter_renewRecordList() {
    return super.post('helpCenter/renewRecordList', ...arguments);
  }
}
