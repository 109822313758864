import Http from '../api/http';
export default class PhoneMessageTemplate extends Http {
  constructor() {
    super();
  }
  /**
   * 新增短信发送模板
   * @param {Array} phoneMessageTemplateDTO -  phoneMessageTemplateDTO
   */
  phoneMessageTemplate_addPhoneMessageTemplate() {
    return super.post('phoneMessageTemplate/addPhoneMessageTemplate', ...arguments, {});
  }

  /**
   * 获取短信模板详情
   * @param {string} id -  id
   */
  phoneMessageTemplate_getPhoneMessageTemplateDetail() {
    return super.post('phoneMessageTemplate/getPhoneMessageTemplateDetail', ...arguments);
  }

  /**
   * 获取模板列表
   */
  phoneMessageTemplate_getPhoneMessageTemplateList() {
    return super.post('phoneMessageTemplate/getPhoneMessageTemplateList', ...arguments);
  }

  /**
   * 获取短信模板分页列表
   * @param {Array} phoneMessageTemplateBO -  phoneMessageTemplateBO
   */
  phoneMessageTemplate_getPhoneMessageTemplateListByPage() {
    return super.post('phoneMessageTemplate/getPhoneMessageTemplateListByPage', ...arguments, {});
  }

  /**
   * 修改短信发送模板
   * @param {Array} phoneMessageTemplateDTO -  phoneMessageTemplateDTO
   */
  phoneMessageTemplate_updatePhoneMessageTemplate() {
    return super.post('phoneMessageTemplate/updatePhoneMessageTemplate', ...arguments, {});
  }
}
