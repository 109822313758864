import Http from '../api/http/';
export default class Message extends Http {
  constructor() {
    super();
  }
  // P端用户消息列表
  message_messageListToP() {
    return super.post('message/messageListToP', ...arguments);
  }
  //消息发送详情
  message_messageSendDetail() {
    return super.post('message/messageSendDetail', ...arguments);
  }
  //读消息
  message_readMsgToP() {
    return super.post('message/readMsgToP', ...arguments);
  }
  //消息发送
  message_sendMessage() {
    return super.post('message/sendMessage', ...arguments);
  }
  //消息发送记录列表
  message_sendRecordList() {
    return super.post('message/sendRecordList', ...arguments);
  }
  // 全部已读
  message_allReadToP() {
    return super.post('message/allReadToP', ...arguments);
  }
  /**
   * 导入
   * @param {string} file -  上传文件
   */
  message_importSendUsers() {
    return super.post('message/importSendUsers', ...arguments);
  }

  /**
   * 预先检查消息发送数量
   * @param {Array} req -  req
   */

  message_preSendMessageCount() {
    return super.post('message/preSendMessageCount', ...arguments, {});
  }
}
