import Http from '../api/http/';
export default class OvertimeReport extends Http {
  constructor() {
    super();
  }
  /***********超时报告每日详情***************/

  /**
   * 导出超时报告列表(新)
   * @param {Array} overtimeDto -  overtimeDto
   */
  overtimeReport_exportOvertimeList() {
    return super.post('overtimeReport/exportOvertimeList', ...arguments, {});
  }

  /**
   * 按日期范围查询超时列表（新）
   * @param {Array} overtimeDto -  overtimeDto
   */
  overtimeReport_getOvertimeList() {
    return super.post('overtimeReport/getOvertimeList', ...arguments, {});
  }
  /**
   * 查询超时详情按品牌分组
   * @param {Array} overtimeDto -  overtimeDto
   */
  overtimeReport_getOvertimeDetailGroupByBrand() {
    return super.post('overtimeReport/getOvertimeDetailGroupByBrand', ...arguments, {});
  }
  /**
   * 导出超时报表明细
   * @param {Array} overtimeDto -  overtimeDto
   */
  overtimeReport_exportOvertimeDetail() {
    return super.post('overtimeReport/exportOvertimeDetail', ...arguments, {});
  }
}
