import Http from '../api/http/';
export default class CompanyDetective extends Http {
  constructor() {
    super();
  }
  // 批量移出私探
  companyDetective_batchRemovePrivateCustomer() {
    return super.post('companyDetective/batchRemovePrivateCustomer', ...arguments);
  }
  // 批量设为私探
  companyDetective_batchSetPrivateCustomer() {
    return super.post('companyDetective/batchSetPrivateCustomer', ...arguments);
  }
  // 导出嘉宾
  companyDetective_exportCompanyDetective() {
    return super.get('companyDetective/exportCompanyDetective', ...arguments);
  }
  // 获取邀请私探二维码key
  companyDetective_generateInviteDetectiveKey() {
    return super.post('companyDetective/generateInviteDetectiveKey', ...arguments);
  }
  // 嘉宾列表
  companyDetective_list() {
    return super.post('companyDetective/list', ...arguments);
  }
  // 移除私探
  companyDetective_removePrivateCustomer() {
    return super.post('companyDetective/removePrivateCustomer', ...arguments);
  }
  // 设为私探
  companyDetective_setPrivateCustomer() {
    return super.post('companyDetective/setPrivateCustomer', ...arguments);
  }
  /**
   * 移除用户标签
   * @param {Array} userLabelDto -  userLabelDto
   */

  companyDetective_removeUserLabelMark() {
    return super.post('companyDetective/removeUserLabelMark', ...arguments);
  }
  /**
   * 添加用户标签
   * @param {Array} userLabelDto -  userLabelDto
   */

  companyDetective_addUserLabelMark() {
    return super.post('companyDetective/addUserLabelMark', ...arguments);
  }
}
