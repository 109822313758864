import Http from '../api/http/';

export default class CompanyRole extends Http {
  constructor() {
    super();
  }
  // 获取全部菜单
  companyRole_getAllMenuList() {
    return super.post('companyRole/getAllMenuList', ...arguments);
  }
  // 修改自定义角色/权限
  companyRole_updateUserDefinedRole() {
    return super.post('companyRole/updateUserDefinedRole', ...arguments);
  }
  // 获取角色详情
  companyRole_getRoleDetail() {
    return super.post('companyRole/getRoleDetail', ...arguments);
  }
  /**
   * 查询默认企业角色菜单权限
   * @param {integer} operationType -  0代运营 1自运营 2自+代运营
   * @param {integer} roleId -  角色
   */
  companyRole_listDefaultCompanyRolePermission() {
    return super.post('companyRole/listDefaultCompanyRolePermission', ...arguments);
  }
  // 角色下拉
  companyRole_getCompanyRoleList() {
    return super.post('companyRole/getCompanyRoleList', ...arguments);
  }
}
