import Http from '../api/http/';
export default class Brand extends Http {
  constructor() {
    super();
  }
  /***********品牌企业管理***************/
  /**
   * 添加品牌
   * @param {string} logo -  品牌图片
   * @param {string} brandName -  品牌名称
   * @param {string} desc -  品牌描述
   * @param {string} company -  所属企业
   * @param {string} contact -  联系人
   * @param {string} phone -  联系方式
   * @param {string} togetherDate -  合作时间
   * @param {integer} isFirst -  联系方式
   * @param {string} uid -  品牌负责人
   */
  brand_addBrandInfo() {
    return super.post('brand/addBrandInfo', ...arguments);
  }

  /**
   * 品牌删除
   * @param {string} id -  品牌Id
   */
  brand_delete() {
    return super.post('brand/delete', ...arguments);
  }
  /**
   * 获取品牌详情
   * @param {string} id -  品牌Id
   */
  brand_getBrandInfo() {
    return super.post('brand/getBrandInfo', ...arguments);
  }

  /**
   * 获取品牌列表
   * @param {string} name -  品牌名称
   * @param {string} company -  企业
   * @param {string} status -  品牌状态
   * @param {integer} page -  页数
   * @param {integer} rows -  页面容量
   */
  brand_getBrandPage() {
    return super.post('brand/getBrandPage', ...arguments, {});
  }

  /**
   * 修改品牌
   * @param {string} logo -  品牌图片
   * @param {string} bid -  品牌名称
   * @param {string} brandName -  品牌名称
   * @param {string} desc -  品牌描述
   * @param {string} company -  所属企业
   * @param {string} togetherDate -  合作时间
   * @param {string} contact -  联系人
   * @param {string} phone -  联系方式
   * @param {integer} isFirst -  联系方式
   * @param {string} uid -  品牌负责人
   */
  brand_updateBrandInfo() {
    return super.post('brand/updateBrandInfo', ...arguments);
  }

  /**
   * 修改品牌状态
   * @param {string} ids -  品牌Id
   * @param {string} status -  品牌状态
   */
  brand_updateBrandStatus() {
    return super.post('brand/updateBrandStatus', ...arguments);
  }
  //获取企业品牌列表
  brand_getCompanyBrandList() {
    return super.post('brand/getCompanyBrandList', ...arguments);
  }
  // 品牌启用(树状调用接口
  brand_operateBrand() {
    return super.post('brand/operateBrand', ...arguments);
  }
  // 导出品牌列表
  brand_exportBrandlList() {
    return super.post('brand/exportBrandlList', ...arguments, {});
  }
  /**
   * 全部品牌列表
   * @param {string} name -  品牌名称
   * @param {string} company -  企业
   * @param {string} status -  品牌状态
   * @param {string} ctimeStart -  创建时间yyyy-mm-dd
   * @param {string} ctimeEnd -  创建时间yyyy-mm-dd
   * @param {string} brandLeaderId -  品牌负责人id
   * @param {string} departmentId -  部门id
   * @param {integer} page -  页数
   * @param {integer} rows -  页面容量
   */

  brand_getAllBrandPage() {
    return super.post('brand/getAllBrandPage', ...arguments);
  }
  /**
   * 全部品牌列表(默认包含禁用品牌)
   * @param {string} companyId -  企业id
   * @param {string} status -  品牌状态
   */

  brand_getAllBrandList() {
    return super.post('brand/getAllBrandList', ...arguments);
  }
  /**
   * @description 通过部门获取品牌列表
   * @param deptId 部门id
   * @returns
   */
  brand_getDeptBrandInfoList() {
    return super.post('brand/getDeptBrandInfoList', ...arguments);
  }
  /**
   * @description 通过部门获取品牌负责人
   * @param deptId 部门id
   * @returns
   */
  brand_getDeptBrandChargrList() {
    return super.post('brand/getDeptBrandChargrList', ...arguments);
  }
  /**
   * 获取企业id名称
   * @param {string} id -  品牌Id
   */

  brand_getCompanyNameByBrandId() {
    return super.post('brand/getCompanyNameByBrandId', ...arguments);
  }
}
