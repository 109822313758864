import Http from '../api/http/';

export default class ExportExcel extends Http {
  constructor() {
    super();
  }

  //导出付款明细
  exportExcel_exportPayInfo() {
    return super.get('exportExcel/exportPayInfo', ...arguments);
  }

  //报表付款明细导出
  exportExcel_exportMonthlyPayInfo() {
    return super.get('exportExcel/exportMonthlyPayInfo', ...arguments);
  }

  //报表付款导出付款单据统计
  exportExcel_exportMonthlyPayList() {
    return super.get('exportExcel/exportMonthlyPayList', ...arguments);
  }

  //报表付款导出付款列表人员详细统计
  exportExcel_exportMonthlyPayVisitorList() {
    return super.get('exportExcel/exportMonthlyPayVisitorList', ...arguments);
  }

  /**
   * 导出业务计件表
   * @param year
   * @param month
   * @param operatorId
   * @param enterpriseId
   */
  exportExcel_businessPieceWorkStatistics() {
    return super.get('exportExcel/businessPieceWorkStatistics', ...arguments);
  }

  /**
   * 导出财务收支表
   * @param year
   * @param month
   * @param operatorId
   * @param brandId
   * @param enterpriseId
   */
  exportExcel_financialRevenuesStatistics() {
    return super.get('exportExcel/financialRevenuesStatistics', ...arguments);
  }
  //导出中奖明细
  exportExcel_exportBusinessDrawStatistic() {
    return super.get('exportExcel/exportBusinessDrawStatistic', ...arguments);
  }
  //导出商圈用户报表
  exportExcel_exportBusinessUserDetailStatistic() {
    return super.get('exportExcel/exportBusinessUserDetailStatistic', ...arguments);
  }
  //导出商圈门店情况报表
  exportExcel_exportBusinessStoreStatistic() {
    return super.get('exportExcel/exportBusinessStoreStatistic', ...arguments);
  }

  //导出成功执行任务的访客
  exportExcel_exportValidReportUsersByTaskId() {
    return super.get('exportExcel/exportValidReportUsersByTaskId', ...arguments);
  }

  /**
   * 导出超时待审核报表
   * @param {Array} reportOvertimeDto -  reportOvertimeDto
   */

  exportExcel_exportOverTimeToAuditList() {
    return super.get('exportExcel/exportOverTimeToAuditList', ...arguments);
  }
}
