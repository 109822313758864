import Http from '../api/http/';

export default class SysArea extends Http {
  constructor() {
    super();
  }
  /***********国家级标准省市区***************/
  /**
   * 省市联动数据
   */
  sysArea_getSysAreaList() {
    return super.post('sysArea/getSysAreaList', ...arguments);
  }
  /**
   * 国家省城市
   */
  sysArea_getCountryCityList() {
    return super.post('sysArea/getCountryCityList', ...arguments);
  }
  /**
   * 通过城市 区 查id
   * cityName 城市名称
   * countyName 区名称
   */
  sysArea_getCityAndCounty() {
    return super.post('sysArea/getCityAndCounty', ...arguments);
  }
  /**
   * 根据城市id查询区县
   * @param {array} cityIds -  城市id
   */
  sysArea_getCountyByCityIds() {
    return super.post('sysArea/getCountyByCityIds', ...arguments, {});
  }
  /**
   * cityName 城市名称
   * countyName 区名称
   * @returns
   */
  sysArea_getCountyInfo() {
    return super.post('sysArea/getCountyInfo', ...arguments);
  }
}
