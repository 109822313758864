import Http from '../api/http/';

export default class ReportTemplate extends Http {
  constructor() {
    super();
  }

  /***********在线报告模板管理***************/
  /**
   * 报告模板管理添加
   * @param {Array} reportTemplateDto -  reportTemplateDto
   */
  reportTemplate_add() {
    return super.post('reportTemplate/add', ...arguments);
  }

  /**
   * 删除
   * @param {string} id -  id
   */
  reportTemplate_delete() {
    return super.post('reportTemplate/delete', ...arguments);
  }

  /**
   * 通过id查询报告模板
   * @param {string} id -  id
   */
  reportTemplate_getById() {
    return super.post('reportTemplate/getById', ...arguments);
  }

  /**
   * 在线报告模板管理分页查询
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   * @param {string} name -  报告模板名称
   * @param {string} brandId -  品牌id
   * @param {integer} status -  0:草稿 ；1:已发布
   */
  reportTemplate_getListByPage() {
    return super.post('reportTemplate/getListByPage', ...arguments);
  }

  /**
   * 发布
   * @param {Array} reportTemplateDto -  reportTemplateDto
   */
  reportTemplate_publish() {
    return super.post('reportTemplate/publish', ...arguments);
  }

  /**
   * 报告模板管理修改
   * @param {Array} reportTemplateDto -  reportTemplateDto
   */
  reportTemplate_update() {
    return super.post('reportTemplate/update', ...arguments);
  }

  /**
   * 模板下载
   * @param id
   */
  reportTemplate_exportReportTemplate() {
    return super.get('reportTemplate/exportReportTemplate', ...arguments);
  }

  /**
   * 模板导出
   * @returns
   */
  reportTemplate_exportReportTemplateInfo() {
    return super.get('reportTemplate/exportReportTemplateInfo', ...arguments);
  }

  /**
   * 考核点类型
   * @returns
   */
  reportTemplate_getDefaultExamList() {
    return super.post('reportTemplate/getDefaultExamList', ...arguments);
  }

  /**
   * 修改共享
   * @returns
   */
  reportTemplate_updateIsShare() {
    return super.post('reportTemplate/updateIsShare', ...arguments);
  }
  // 树状结构的模板查询
  reportTemplate_getReportTemplateTreeById() {
    return super.post('reportTemplate/getReportTemplateTreeById', ...arguments);
  }
  // 批量导入考核点
  reportTemplate_importTemplateInfo() {
    return super.post('reportTemplate/importTemplateInfo', ...arguments);
  }
  // 检查发布模板修改的报告数
  reportTemplate_updateCheck() {
    return super.post('reportTemplate/updateCheck', ...arguments);
  }
  // 模板自定义公式校验
  reportTemplate_checkScoreFormula() {
    return super.post('reportTemplate/checkScoreFormula', ...arguments);
  }
}
