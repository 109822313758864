import Http from '../api/http/';

export default class Option extends Http {
  constructor() {
    super();
  }
  /**
   * 基础配置，配置分类
   * @returns
   */
  option_getOptionTypes() {
    return super.post('option/getOptionTypes', ...arguments);
  }
  //基本设置分类
  option_getOptionPage() {
    return super.post('option/getOptionPage', ...arguments);
  }
  //增加分类配置信息
  option_addNewOption() {
    return super.post('option/addNewOption', ...arguments);
  }
  //删除分类
  option_deleteOption() {
    return super.post('option/deleteOption', ...arguments);
  }
  //获取分类配置信息
  option_getOptionDetail() {
    return super.post('option/getOptionDetail', ...arguments);
  }
  //修改分类配置信息
  option_updateNewOption() {
    return super.post('option/updateNewOption', ...arguments);
  }
  /**
   * 获取配置列表(key为int型)
   * @param {integer} type -  类型
   */
  option_getIntegerKeyOptionList() {
    return super.post('option/getIntegerKeyOptionList', ...arguments);
  }
}
